import React from 'react'
import styled from 'styled-components'
import { GetStaticProps, NextPage } from 'next'
import { GlobalProps, withLayoutProps } from '../globals/layout'
import { Layout } from '../components/layout/layout'
import { Error, StyledMobileLogo } from '../templates/error'
import { StyledHeaderContainer } from '../components/NavigationDropdown/styledNavDropdownUtilityBar'
import { StyledLogoContainer } from '../components/logo/logo'

export const StyledErrorWrapper = styled.div`
  header {
    display: none;
  }

  footer {
    display: none;
  }

  ${StyledHeaderContainer} {
    display: none;
  }

  ${StyledLogoContainer} {
    margin-bottom: ${props => props.theme.typography.pxToRem(30)};
    display: none;
    ${props => props.theme.breakpoints.up('sm')} {
      margin-bottom: 0;
    }

    ${props => props.theme.breakpoints.up('md')} {
      display: block;
    }

    > div {
      width: 100%;
      text-align: center;
      ${props => props.theme.breakpoints.up('md')} {
        width: auto;
        text-align: left;
      }
    }
  }

  ${StyledMobileLogo} {
    ${StyledLogoContainer} {
      display: block;
    }
  }
`

export type NotFoundPageProps = GlobalProps & {

}

const NotFoundPage: NextPage<NotFoundPageProps> = ({ ...layoutProps }) => {
  return (
    <StyledErrorWrapper>
      <Layout { ...layoutProps }>
        <Error statusCode={'404'} />
      </Layout>
    </StyledErrorWrapper>
  )
}

export default NotFoundPage

export const getStaticProps: GetStaticProps = async () => {
  return withLayoutProps({
    path: '/',
  })
}
